@mixin badge-color($color) {
    border-color: $color;
    background-color: $color;
}

@mixin badge-hover-href($color, $bg-color) {
    color: $color;
    background-color: $bg-color;
    border-color: $bg-color;
}


