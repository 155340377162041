.select2-container {
  z-index: 1060;
  position: initial;
  .select2-selection {
    font-family: $font-family-fira;
  }
}
.select2-container--bootstrap
  .select2-selection--single
  .select2-selection__rendered {
  padding-right: 10px;
}

.select2-container .select2-selection--single .select2-selection__clear {
  padding-left: 7px;
  font-size: 24px;
}

.select2-container--bootstrap {
  &.select2-container--focus .select2-selection,
  .select2-container--bootstrap.select2-container--open .select2-selection {
    border: 1px solid $dark-gray;
    box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075);
  }

  .select2-selection--multiple .select2-selection__choice {
    border-color: $white-color;
    background-color: $success-color;
    color: $white-color;
    border-radius: 12px;
    font-size: 0.75em;
    font-weight: 500;
    line-height: 1.5em;
    padding: 4px 8px 3px 0.8em;

    .select2-selection__choice__remove {
      color: $white-color;
      float: right;
      padding: 0 0 0 5px;
    }
  }

  .select2-results__option--highlighted[aria-selected] {
    background-color: $dark-gray;
  }

  .select2-dropdown {
    border-color: $dark-gray;
  }

  .select2-selection--multiple {
    min-height: 38px;
  }

  .select2-selection--single {
    line-height: 1.2em;
    min-height: 38px;
    padding-left: 16px;
    padding-right: 27px;
    height: 46px;
    display: flex;
    align-items: center;
    border-color: #e8e8e8;

    .select2-selection__arrow {
      width: -webkit-fit-content;
      width: -moz-fit-content;
      width: fit-content;
      right: 30px;

      b {
        width: 10px;
        height: 10px;
        background-image: url("../images/icons/arrow_down.svg");
        border: none;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        margin-top: -5px;
      }
    }

    .select2-selection__placeholder {
      color: #b6b6b6;
      font-size: 16px;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }
}

// TODO: fix select dropdown
.select2-container--open .select2-dropdown--below {
  overflow-x: visible;
  padding-top: 10px;
}
.select2-container--bootstrap .select2-dropdown {
  border-color: rgba(102, 175, 233, 1);
  border-top: none;
  box-shadow: inset 0 0 1px rgba(0, 0, 0, 0.08),
    0 4px 8px rgba(102, 175, 233, 0.6);
  position: relative;
  &:after {
    content: "";
    position: absolute;
    top: -5px;
    left: 0;
    right: 0;
    height: 10px;
    background-color: $white-color;
  }
}
ul.select2-results__options {
  min-width: -webkit-max-content;
  min-width: -moz-max-content;
  min-width: max-content;
  border-radius: 5px;
  border-top-left-radius: 0;
  margin-top: 10px;
  .select2-results__message {
    max-width: fit-content;
  }

  .select2-results__option:not(.select2-results__message) {
    overflow: visible;
    max-width: 70vw;
    box-shadow: -13px 10px 13px 12px rgba(0, 0, 0, 0.18);
    min-width: 100%;
    border-right: 1px solid #eee;

    @include if-smaller-than("xs") {
      min-width: min-content;
    }
    &:first-child {
      border-top-right-radius: 5px;
      border-top: 1px solid #eee;
    }
    &:last-child {
      border-bottom-right-radius: 5px;
      border-bottom-left-radius: 5px;
      box-shadow: 0px -16px 32px -19px #000;
      margin-bottom: 6px;
      border-bottom: 1px solid #eee;
      border-left: 1px solid #eee;
    }
    &:not(.select2-results__option--highlighted) {
      background-color: $white-color;
    }
  }
}
