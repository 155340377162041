@import '../../../style/variables';
@import '../../../style/typography';

.tableList {
  display: flex;
  &--horizontal {
    flex-direction: row;
    justify-content: space-between;
  }
  &--vertical {
    flex-direction: column;
  }

  &__horizontal {
    &ItemContainer {
      display: block;
    }
    &Label {
      font-weight: 700;
      font-size: $font-size-secondary;
      color: $default-600;
      height: 34px;
      display: flex;
      align-items: center;
    }
    &Items {
      display: flex;
      flex-direction: column;
    }
    &Item {
      display: flex;
      font-size: $font-size-secondary;
      height: 34px;
      & > *:nth-child(1) {
        display: flex;
        align-items: center;
      }
    }
  }

  &__vertical {
    &ItemContainer {
      display: flex;
    }
    &Label {
      font-weight: 700;
      font-size: $font-size-secondary;
      color: $default-600;
      height: 34px;
      display: flex;
      align-items: center;
      flex-basis: 50%;
    }
    &Items {
      display: flex;
      flex-direction: column;
      flex-basis: 50%;
    }

    &Item {
      display: flex;
      font-size: $font-size-secondary;
      height: 34px;
      & > *:nth-child(1) {
        display: flex;
        align-items: center;
      }
    }
  }
}
