@import '../../../style/variables';
@import '../../../style/typography';

.richTextInput {
  font-style: normal;
  font-weight: normal;
  width: 100%;
  padding: 11px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $default-300;
  color: $black;
  caret-color: $link-200;
  font-size: $font-size-primary;
  font-family: $font-family-secondary;
  line-height: 22px;
  &::placeholder {
    color: $default-300;
  }
  &:focus,
  &:active {
    outline: none;
  }
  &--error {
    border-color: $danger-100 !important;
  }
  &--disabled {
    background-color: $default-200;
    color: $default-600;
    cursor: not-allowed;
    border: 1px solid $default-300;
  }

  & .jodit-wysiwyg {
    blockquote {
      padding-left: 20px !important;
      border-left: 4px solid $default-500;
      span  {
        font-style: italic;
      }
    }
  }
}
