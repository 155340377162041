@import '../../style/variables';

.calendarTimeline {
  display: flex;
  overflow: hidden;

  &__fixedColumn {
    display: flex;
    flex-direction: column;
    width: 235px !important;
    border-right: 1px solid $default-300;

    > div:not(:last-child) {
      border-bottom: 1px solid $default-300;
    }
  }

  &__emptyHeaderCell {
    height: 61px !important;
    border-bottom: 1px solid $default-300;
  }

  &__scrollContainer {
    overflow-x: auto;
    display: flex;
    flex: 1;
  }

  &__scrollContent {
    display: grid;
    grid-template-columns: repeat(48, 70px);
    grid-template-rows: 61px repeat(auto-fill, 54px);
  }

  &__headerRow {
    display: contents;
  }

  &__monthHeader {
    grid-column-end: span 4;
    text-align: center;
    padding: 16px 0 4px;
    border-right: 1px solid $default-300;
    border-bottom: 1px solid $default-300;
  }

  &__sectionsRow {
    display: flex;
    justify-content: space-between;
    padding-left: 5px;
    padding-right: 3px;
    margin-top: 4px;
  }

  &__section {
    text-align: center;
    color: $default-500;
    font-size: 13px;
    line-height: 15.6px;
  }

  &__eventRow {
    grid-column: 1 / span 48;
    display: grid;
    grid-template-columns: repeat(48, 70px);
  }

  &__cell {
    border-right: 1px solid $default-300;
    height: 54px;
  }

  &__eventWrapper {
    border-right: 1px solid $default-300;
    position: relative;
  }

  &__event {
    background-color: $white;
    border: 1px solid $default-300;
    border-radius: 8px;
    height: 46px;
    position: absolute;
    right: 0;
    left: 0;
    top: 4px;
    width: 100% !important;
    cursor: pointer;

    &--warning {
      border-color: $warning-100;
      background-color: $warning-400;
    }
    &--selected {
      background-color: $default-200;
    }
  }
}
