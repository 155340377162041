.documentPreviewModal {
  &__container {
    background-color: transparent !important;
    box-shadow: none !important;
    margin: 0 !important;
    padding: 0 !important;
    cursor: pointer;
  }

  &__modal {
    max-height: 1000px;
    height: max-content;
    width: 600px;
    padding: 5px 20px 20px;
  }

  &__document {
    height: 800px;
    width: 500px;
  }
}
