.clean-air {

  .dashboard {
    .card {
      cursor: pointer;
      margin-right: 2.5rem;
      width: calc((100% / 3) - 2.5rem);

      &-title {
        border-bottom: 1px solid lightgrey;
        font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
        font-weight: 300;
        margin-bottom: 1rem;
        padding-bottom: 0.5rem;
        text-align: center;
      }

      &-body {
        display: flex;
        flex-direction: column;
      }

      &-icon {
        flex: 0 0 40%;
        padding: 0.5rem;
        text-align: center;
      }

      &-status {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        margin-right: 1rem;

        .fa {
          color: $success-color;
          font-size: 3rem;

          &.fa-exclamation-triangle {
            color: $danger-color;
          }
        }
      }
    }
  }

  .btn.btn-default {
    font-size: 0.75rem;
  }

  .nav {
    .nav-item {

      .nav-link {
        border-radius: 5px !important;
      }

      .nav-link + .nav-link {
        margin-top: 0.5rem;
      }
    }
  }

  .tab-content {

    &.hidden {
      display: none;
    }

    .card {
      text-align: center;
      width: 30%;

      &-title {
        margin: 0;
      }
    }
  }

  .tempVal,
  .tempValue {
    &::after {
      content: "°C";
    }

    &::before {
      content: "+";
    }
  }

  .co2Val::after,
  .co2Value::after {
    content: "ppm";
  }

  .humVal::after,
  .humValue::after {
    content: "%";
  }

  .alert {
    display: none;

    p {
      font-weight: bold;
      margin: 0;
    }
  }

  .charts .card {
    width: 100%;
  }

  .success {
    color: $success-color;
  }

  .danger {
    color: $danger-color;
  }

  .warning {
    color: $warning-color;
  }
}
