.vacationChart {
  &__pie {
    position: relative;
  }

  &__total {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &__marker {
    height: 20px;
    width: 20px;
    border-radius: 5px;
  }
}
