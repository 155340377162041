// main colors

$default-100: #f4f3ef;
$default-200: #f5f5f5;
$default-300: #dedede;
$default-400: #b6b6b6;
$default-500: #8f8f8f;
$default-600: #5b5651;
$default-700: #383532;
$default-800: #2c2c2c;
$default-900: #1c1c1c;

$primary-100: #47c4c7;
$primary-200: #2eacaf;

$info-100: #47b4d5;
$info-200: #269fc6;

$success-100: #60ca8d;
$success-200: #3cbc72;
$success-300: #0DB257;

$warning-100: #fabf4e;
$warning-200: #f9ac22;
$warning-300: #EDB31E;
$warning-400: #FFFAEF;

$danger-100: #ed764d;
$danger-200: #e85a2c;

$link-100: #2f93ff;
$link-200: #007bff;

$dark-100: $default-600;
$dark-200: $default-700;

$white: #fff;
$black: #000;
$red: #D22323;

// components colors
$border-main: #e9e9e9;
$background-color: $white;
$text-color: $black;
$bank-icon-background: #dbebfd;
$payoneer-red: #ef8157;
$banner-background-color: #212123;
$tags-background-blue: #D8E5FF;
$tags-blue: #0334FA;
$tags-background-pink: #FBDFEA;
$tags-pink: #D8107E;
$tags-background-purple: #DDE1FF;
$tags-purple: #6D08FC;
$tags-background-orange: #FFEBDD;
$tags-orange: #FC6E08;
$text-purple: #5761F7;
$purple-secondary: #8D93EF;
$yellow-primary: #F3C716;
$multiple-selector-text: #6630EA;
$multiple-selector-background: #6C54FF1A;

// transitions
$btn-transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;
