@import "../../style/typography";
@import "../../style/variables";

.ui-table {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;

  &--horizontalScroll {
    overflow-x: auto;
  }

  &--scrollable {
    overflow-y: auto;
    border-radius: 8px;

    table {
      border-collapse: collapse;
      width: 100%;

      thead, tfoot tr {
        position: sticky;
        z-index: 3;

        &::after {
          content: '';
          position: absolute;
          z-index: 1;
          left: 0;
          height: 1px;
          width: 100%;
        }
      }
      @-moz-document url-prefix() {
        th {
          border-right: none !important;
        }
        th:not(:last-child)::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          border-right: 1px solid $default-300;
        }
      }

      tfoot tr {
        bottom: -1px;
        border-top: none;

        &::after {
          top: 0;
          border-top: 1px solid $default-600;
        }
      }

      thead {
        top: 0;
        border-bottom: none;

        &::after {
          bottom: 0;
          border-bottom: 1px solid $default-600;
        }
      }
    }
  }

  &__loadingRow {
    background-color: $white;
  }

  &__headerRow {
    border-bottom: 1px solid $default-600;
    background-color: $white;

    &Checkbox {
      margin: 0 !important;

      &Cell {
        width: 50px;
      }
    }
  }

  &__headerCell {
    border-collapse: collapse;
    font-family: $font-family-navigation;
    font-weight: 700;
    font-size: $font-size-secondary;
    line-height: 18px;
    text-transform: uppercase;
    color: $black;
    padding: 22px 16px;
    position: relative;

    &:not(:last-child) {
      border-right: 1px solid $default-300;
    }

    &--dark {
      background-color: $default-200;
    }

    &--minimalPadding {
      padding: 8px 16px;
    }

    &--noWeight {
      font-weight: normal;
    }

    &--withHover {
      &:hover {
        background-color: $default-200;
        cursor: pointer;
      }
    }

    &--noBorder {
      border-left: none !important;
      border-right: none !important;
    }

    &Border--bottom {
      position: absolute;
      bottom: -1px;
      left: 0;
      right: 0;
      width: 100%;
      height: 1px;
      background-color: $default-600;
    }

    &Border--right {
      border-right: 1px solid $default-300;

      @-moz-document url-prefix() {
        border-right: none !important;

        &::after {
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          height: 100%;
          border-right: 1px solid $default-300;
        }
      }
    }
  }

  &__row {
    &:not(:last-child) {
      border-bottom: 1px solid $default-300;
    }

    &--withHover {
      &:hover {
        background-color: $default-200;
        cursor: pointer;
      }
    }

    &--white {
      background-color: $white;
    }

    &--dark {
      background-color: $default-200;
    }

    &--success {
      background-color: #EAF9EB;

      &:not(:last-child) {
        border-bottom: 1px solid $success-100 !important;
      }
    }

    &--warning {
      background-color: #FBF1EC;

      &:not(:last-child) {
        border-bottom: 1px solid $danger-100 !important;
      }
    }

    &--attention {
      background-color: #FFFAEF;

      &:not(:last-child) {
        border-bottom: 1px solid $warning-100 !important;
      }
    }

    &--hidden {
      display: none;
    }

    &Border--top {
      border-top: 1px solid $default-600;
    }

    &Checkbox {
      margin: 0 !important;

      &Cell {
        width: 50px;
      }
    }
  }

  &__cell {
    font-family: $font-family-secondary;
    font-weight: 400;
    font-size: $font-size-secondary;
    line-height: 21px;
    color: $default-800;
    padding: 14px 16px;
    border-collapse: collapse;

    &:not(:last-child) {
      border-right: 1px solid $default-300;
    }

    &--noBorder {
      border-left: none !important;
      border-right: none !important;
    }
  }
}
