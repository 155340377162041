@import '../../style/variables';
@import '../../style/typography';

.ui-modal {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.15);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  display: flex;
  align-items: center;
  justify-content: center;

  &__content {
    max-height: 85vh;
    overflow-y: auto;
    border-radius: $spacingBase;
  }

  &__title {
    margin-bottom: 0 !important;
    padding: 3px 0;
  }
}
