@import "../../../style/variables";
@import "../../../style/typography";


.switch {
  position: relative;
  display: block;
  width: 50px;
  height: 24px;

  input {
    opacity: 0;
    width: 0;
    height: 0;
    &:checked + .switch__slider {
      background-color: $success-100;
    }
    &:checked:hover + .switch__slider {
      background-color: $success-200;
    }
    &:hover + .switch__slider {
      background-color: $default-400;
    }
    &:checked + .switch__slider:before {
      -webkit-transform: translateX(21px);
      -ms-transform: translateX(21px);
      transform: translateX(21px);
    }
    &:checked + .switch--disabled:before {
      -webkit-transform: none !important;
      -ms-transform: none !important;
      transform: none !important;
    }
  }

  &__slider {
    position: absolute;
    cursor: pointer;
    border-radius: 36.5px;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $default-300;
    -webkit-transition: .4s;
    transition: .4s;
    &:before {
      position: absolute;
      content: "";
      height: 20px;
      width: 25px;
      left: 2px;
      bottom: 2px;
      background-color: $white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 100px;
    }
  }

  &__label {
    width: max-content;
    margin-left: 66px;
    font-family: $font-family-secondary;
    font-weight: $font-weight-normal;
    font-size: $fontSizeNormal;
    color: $default-800;
  }

  &--disabled {
    background-color: $default-200 !important;
    cursor: not-allowed;
  }
}
