@import "../../style/typography";

$paddingHorizontalXSmall:   $spacingBase;                    // 4px
$paddingHorizontalSmall:    $spacingBase * 2;                // 8px
$paddingHorizontalNormal:   $spacingBase * 4;                // 16px
$paddingHorizontalLarge:    $spacingBase * 5;                // 20px
$paddingHorizontalXLarge:   $spacingBase * 6;                // 24px
$paddingHorizontalXXLarge:  $spacingBase * 8;                // 32px
$paddingHorizontalXXXLarge:  $spacingBase * 12;              // 48px

$paddingVerticalXSmall:     $paddingHorizontalXSmall;         // 4px
$paddingVerticalSmall:      $paddingHorizontalSmall;          // 8px
$paddingVerticalNormal:     $paddingHorizontalNormal;         // 16px
$paddingVerticalLarge:      $paddingHorizontalLarge;          // 20px
$paddingVerticalXLarge:     $paddingHorizontalXLarge;         // 24px
$paddingVerticalXXLarge:    $paddingHorizontalXXLarge;        // 32px
$paddingVerticalXXXLarge:    $paddingHorizontalXXXLarge;      // 48px

.padding {
  &__top {
    padding-top: $paddingVerticalNormal;

    &--XSmall { padding-top: $paddingVerticalXSmall; }
    &--Small { padding-top: $paddingVerticalSmall; }
    &--Large { padding-top: $paddingVerticalLarge; }
    &--XLarge { padding-top: $paddingVerticalXLarge; }
    &--XXLarge { padding-top: $paddingVerticalXXLarge; }
    &--XXXLarge { padding-top: $paddingVerticalXXXLarge; }
  }

  &__bottom {
    padding-bottom: $paddingVerticalNormal;

    &--XSmall { padding-bottom: $paddingVerticalXSmall; }
    &--Small { padding-bottom: $paddingVerticalSmall; }
    &--Large { padding-bottom: $paddingVerticalLarge; }
    &--XLarge { padding-bottom: $paddingVerticalXLarge; }
    &--XXLarge { padding-bottom: $paddingVerticalXXLarge; }
    &--XXXLarge { padding-bottom: $paddingVerticalXXXLarge; }
  }

  &__left {
    padding-left: $paddingHorizontalNormal;

    &--XSmall { padding-left: $paddingHorizontalXSmall; }
    &--Small { padding-left: $paddingHorizontalSmall; }
    &--Large { padding-left: $paddingHorizontalLarge; }
    &--XLarge { padding-left: $paddingHorizontalXLarge; }
    &--XXLarge { padding-left: $paddingHorizontalXXLarge; }
    &--XXXLarge { padding-left: $paddingHorizontalXXXLarge; }
  }

  &__right {
    padding-right: $paddingHorizontalNormal;

    &--XSmall { padding-right: $paddingHorizontalXSmall; }
    &--Small { padding-right: $paddingHorizontalSmall; }
    &--Large { padding-right: $paddingHorizontalLarge; }
    &--XLarge { padding-right: $paddingHorizontalXLarge; }
    &--XXXLarge { padding-right: $paddingHorizontalXXXLarge; }
  }
}
