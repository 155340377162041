@import '../../style/typography';
@import '../../style/variables';

.achievement {
  &__container {
    width: 310px;
    height: 416px;
    border-radius: 10px;
  }

  &__button {
    display: inline-block;
    position: relative;
    border-radius: 50%;
    margin: 0 20px 15px 20px;
    &:hover {
      cursor: pointer;
    }

    &Count {
      width: 35px;
      height: 35px;
      border-radius: 20px;
      position: absolute;
      right: 0px;
      border: $default-100 solid 3px;
      background-color: $success-200;
    }
  }

  &__image {
    border: $default-100 3px solid;
    border-radius: 50%;
    background-size: cover;
    background-position: center;
    &--small {
      height: 70px;
      width: 70px;
    }
    &--normal {
      height: 100px;
      width: 100px;
    }
    &--large{
      height: 180px;
      width: 180px;
    }
  }

  &__newBadge {
    width: 55px;
    height: 35px;
    border-radius: 20px;
    position: absolute;
    right: 0;
    border: $default-100 solid 3px;
    background-color: $tags-purple;
  }

  &__progress {
    border-radius: 50%;
    &--small {
      height: 77px;
      width: 77px;
    }
    &--normal {
      height: 107px;
      width: 107px;
    }
    &--large{
      height: 187px;
      width: 187px;
    }
  }

  &__logoCount {
    background-color: $warning-100;
    margin-top: 5px;
    font-size: 14px;
    border-radius: 100px;
    display: inline-block;
    padding: 3px 10px;
  }
}
