@import '../../style/typography';
@import '../../style/variables';

@mixin icon-solid(
  $className,
  $color,
) {
  &.icon--#{$className} {
    background-color: $color;
    color: $white;
    border: 1px solid $color;
  }
}

@mixin icon-outline(
  $className,
  $color,
) {
  &.icon--#{$className} {
    background-color: transparent;
    color: $color;
    border: 1px solid $color;
  }
}

.icon {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;

  &:active,
  &:focus {
    outline: none;
  }

  &__circle {
    border-radius: 50%;
  }

  &__square {
    border-radius: 4px;
  }

  &--noBorder {
    border: none !important;
  }

  &--xsmall {
    svg {
      width: 16px;
      height: 16px;
    }
  }

  &--small {
    svg {
      width: 20px;
      height: 20px;
    }
  }

  &--normal {
    svg {
      width: 30px;
      height: 30px;
    }
  }

  &--large {
    svg {
      width: 40px;
      height: 40px;
    }
  }


  &__solid {
    @include icon-solid('success', $success-100);
    @include icon-solid('warning', $warning-100);
    @include icon-solid('danger', $danger-100);
    @include icon-solid('dark', $default-500);
    @include icon-solid('white', $white);
    @include icon-solid('default', $default-800);
  }

  &__outline {
    @include icon-outline('success', $success-100);
    @include icon-outline('warning', $warning-100);
    @include icon-outline('danger', $danger-100);
    @include icon-outline('dark', $default-500);
    @include icon-outline('white', $white);
    @include icon-outline('default', $default-800);
  }
}
