$justifyAligns: (
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'between': space-between,
  'around': space-around,
  'evenly': space-evenly
);

$contentAligns: (
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'baseline': baseline,
  'stretch': stretch,
  'between': space-between,
  'around': space-around,
  'evenly': space-evenly
);

$itemsAligns: (
  'start': flex-start,
  'end': flex-end,
  'center': center,
  'baseline': baseline,
  'stretch': stretch
);

@mixin justifyContent($classDir, $dir) {
  &__justifyContent--#{$classDir} {
    justify-content: $dir;
  }
}

@mixin alignContent($classDir, $dir) {
  &__alignContent--#{$classDir} {
    align-content: $dir;
  }
}

@mixin alignItems($classDir, $dir) {
  &__alignItems--#{$classDir} {
    align-items: $dir;
  }
}

.flex {
  display: flex;
  &__flexRow {
    flex-direction: row;
  }
  &__flexCol {
    flex-direction: column;
  }
  &__wrap {
    flex-wrap: wrap;
  }
  &__nowrap {
    flex-wrap: nowrap;
  }
  &__flexGrowZero {
    flex-grow: 0;
  }
  &__flexGrowOne {
    flex-grow: 1;
  }
  &__flexShrinkZero {
    flex-shrink: 0;
  }
  &__flexShrinkOne {
    flex-shrink: 1;
  }
  @each $contentAlign, $dir in $contentAligns {
    @include justifyContent($contentAlign, $dir)
  }

  @each $contentAlign, $dir in $contentAligns {
    @include alignContent($contentAlign, $dir)
  }

  @each $itemsAlign, $dir in $itemsAligns {
    @include alignItems($itemsAlign, $dir)
  }
}
