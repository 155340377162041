@import '../../style/variables';
@import '../../style/typography';
@import '../../style/mixins';

.bankIcon {
  @include flex-center;
  @include square($font-size-h4, $radius: 50%);
  background-color: $bank-icon-background;
  line-height: initial;
  color: $text-color;

  &--xsmall {
    width: 16px;
    height: 16px;
    border-radius: 50%;
    font-size: $fontSizeXSMALL;
  }

  &--small {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: $fontSizeSMALL;
  }

  &--normal {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    font-size: $font-size-secondary;
  }

  &--large {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    font-size: $fontSizeLarge;
  }

  &--payoneer {
    background-color: $payoneer-red;
    color: $white;
  }
}
