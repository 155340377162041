@import "../../style/typography";
@import "../../style/variables";

$spacerHorizontalXSmall:    $spacingBase;                   // 4px
$spacerHorizontalSmall:     $spacingBase * 2;               // 8px
$spacerHorizontalNormal:    $spacingBase * 4;               // 16px
$spacerHorizontalLarge:     $spacingBase * 5;               // 20px
$spacerHorizontalXLarge:    $spacingBase * 6;               // 24px
$spacerHorizontalXXLarge:   $spacingBase * 10;              // 40px

$spacerVerticalXSmall:      $spacerHorizontalXSmall;        // 4px
$spacerVerticalSmall:       $spacerHorizontalSmall;         // 8px
$spacerVerticalNormal:      $spacerHorizontalNormal;        // 16px
$spacerVerticalLarge:       $spacerHorizontalLarge;         // 20px
$spacerVerticalXLarge:      $spacerHorizontalXLarge;        // 24px
$spacerVerticalXXLarge:     $spacerHorizontalXXLarge;       // 40px

$spacerLineColor:           $default-300;

.spacer {
  position: relative;
  display: block;

  &__vertical {
    display: block;

    &--XSmall .spacerContent { padding-top: $spacerVerticalXSmall; }
    &--Small .spacerContent { padding-top: $spacerVerticalSmall; }
    &--Normal .spacerContent { padding-top: $spacerVerticalNormal; }
    &--Large .spacerContent { padding-top: $spacerVerticalLarge; }
    &--XLarge .spacerContent { padding-top: $spacerVerticalXLarge; }
    &--XXLarge .spacerContent { padding-top: $spacerVerticalXXLarge; }

    .spacerContent {
      &__line {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $spacerLineColor;
      }
    }

    &BothSides {
      &.spacer__vertical {
        &--XSmall .spacerContent { padding-top: $spacerVerticalXSmall; padding-bottom: $spacerVerticalXSmall; }
        &--Small .spacerContent { padding-top: $spacerVerticalSmall; padding-bottom: $spacerVerticalSmall; }
        &--Normal .spacerContent { padding-top: $spacerVerticalNormal; padding-bottom: $spacerVerticalNormal; }
        &--Large .spacerContent { padding-top: $spacerVerticalLarge; padding-bottom: $spacerVerticalLarge; }
        &--XLarge .spacerContent { padding-top: $spacerVerticalXLarge; padding-bottom: $spacerVerticalXLarge; }
        &--XXLarge .spacerContent { padding-top: $spacerVerticalXXLarge; padding-bottom: $spacerVerticalXXLarge; }
      }

      .spacerContent {
        &__line {
          display: block;
          width: 100%;
          height: 1px;
          background-color: $spacerLineColor;
        }
      }
    }
  }

  &__horizontal {
    display: inline-block;

    &--XSmall .spacerContent { padding-left: $spacerHorizontalXSmall; }
    &--Small .spacerContent { padding-left: $spacerHorizontalSmall; }
    &--Normal .spacerContent { padding-left: $spacerHorizontalNormal; }
    &--Large .spacerContent { padding-left: $spacerHorizontalLarge; }
    &--XLarge .spacerContent { padding-left: $spacerHorizontalXLarge; }
    &--XXLarge .spacerContent { padding-left: $spacerHorizontalXXLarge; }

    .spacerContent {
      &__line {
        display: block;
        position: absolute;
        width: 1px;
        height: 100%;
        background-color: $spacerLineColor;
      }
    }

    &BothSides {
      &.spacer__horizontal {
        &--XSmall .spacerContent { padding-left: $spacerHorizontalXSmall; padding-right: $spacerHorizontalXSmall; }
        &--Small .spacerContent { padding-left: $spacerHorizontalSmall; padding-right: $spacerHorizontalSmall; }
        &--Normal .spacerContent { padding-left: $spacerHorizontalNormal; padding-right: $spacerHorizontalNormal; }
        &--Large .spacerContent { padding-left: $spacerHorizontalLarge; padding-right: $spacerHorizontalLarge; }
        &--XLarge .spacerContent { padding-left: $spacerHorizontalXLarge; padding-right: $spacerHorizontalXLarge; }
        &--XXLarge .spacerContent { padding-left: $spacerHorizontalXXLarge; padding-right: $spacerHorizontalXXLarge; }
      }

      .spacerContent {
        &__line {
          display: block;
          position: absolute;
          width: 1px;
          height: 100%;
          background-color: $spacerLineColor;
        }
      }
    }
  }
}
