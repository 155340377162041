// customizations for default blueprintjs components styles
@import "@blueprintjs/popover2/lib/css/blueprint-popover2.css";
@import "@blueprintjs/core/lib/css/blueprint.css";

.bp5-toast {
  align-items: center;
  border-radius: 8px;
}

.bp5-toast-container {
  z-index: 2000;

  &.bp5-toast-container-inline {
    position: fixed;
  }
}

a:hover {
  text-decoration: none;
}
