@import '../../../style/variables';
@import '../../../style/typography';

.ui-selectInput {
  .selectInput__menu {
    z-index: 10;
  }

  &--multiple {
    .css-1rhbuit-multiValue {
      padding: 0 8px;
      border-radius: 26px;
      background-color: $multiple-selector-background;
      color: $multiple-selector-text;
      margin: 0 4px 0 0;
    }

    .css-12jo7m5 {
      color: $multiple-selector-text;
      padding: 0;
    }

    .css-xb97g8 {
      &:hover {
        background-color: transparent;
        color: $multiple-selector-text;
      }
    }

    .selectInput__clear-indicator {
      display: none;
    }
  }

  .selectInput__control {
    font-style: normal;
    font-weight: normal;
    width: 100%;
    min-height: unset;
    box-sizing: border-box;
    border-radius: 4px;
    border: 1px solid $default-300;
    color: $black;
    caret-color: $link-200;
    font-size: $font-size-primary;
    font-family: $font-family-secondary;
    line-height: 24px;
    box-shadow: none;

    &:hover, &:active, &:focus {
      box-shadow: none;
      outline: none;
      border: 1px solid $default-400;
    }

    .selectInput__value-container {
      padding: 11px 8px !important;
      gap: 10px;

      .selectInput__placeholder {
        color: $default-300;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .selectInput__input-container {
        margin: 0;
        padding: 0;
        color: inherit;
      }
    }

    .selectInput__indicators {
      .selectInput__indicator-separator {
        display: none;
      }
    }
  }

  &--error {
    .selectInput__control {
      border-color: $danger-100;
    }
  }

  &--disabled {
    .selectInput__control {
      background-color: $default-200;
      cursor: not-allowed;
      border: 1px solid $default-300;
      color: $default-600;
    }
  }

  &--transparent {
    .selectInput__control {
      background-color: transparent;
    }
  }

  &--small {
    .selectInput__control {
      font-size: $font-size-description;
      line-height: 18px;

      .selectInput__value-container {
        padding: 2px 4px;
      }

      .selectInput__indicators {
        .selectInput__indicator-separator {
          padding: 2px 4px;
        }
      }
    }
  }

  &--fullwidth {
    width: 100%;
  }

  .selectInput__multi-value {
    background-color: #EDEFFF;
    margin: 0;
    border-radius: 26px;
  }

  .selectInput__multi-value__label {
    color: $text-purple;
    font-family: $font-family-secondary;
    font-size: $font-size-description;
    font-weight: 400;
    line-height: 1.23;
    padding: 4px 8px 4px 8px;
  }

  .selectInput__multi-value__remove {
    padding-left: 0;
    padding-right: 8px;
    svg {
      width: 18px;
      height: 18px;
      fill: $text-purple;
    }
  }

  .selectInput__multi-value__remove:hover {
    background-color: transparent;
    color: $text-purple;
  }

  .selectInput__multi-value__remove:focus {
    outline: none;
  }
}

.selectInput__clear-indicator {
  background-color: white !important;
  //content: url('../../../assets/images/clear-icon.svg') !important;
  outline: none;
}
