@font-face {
  font-family: 'FontAwesome';
  src: url('font-awesome/fonts/fontawesome-webfont.eot');
  src: url('font-awesome/fonts/fontawesome-webfont.eot?#iefix') format('embedded-opentype'),
  url('font-awesome/fonts/fontawesome-webfont.woff2') format('woff2'),
  url('font-awesome/fonts/fontawesome-webfont.woff') format('woff'),
  url('font-awesome/fonts/fontawesome-webfont.ttf') format('truetype'),
  url('font-awesome/fonts/fontawesome-webfont.svg#fontawesome') format('svg');
  font-weight: normal;
  font-style: normal;
}
