@import "../../../style/variables";
@import 'react-datepicker/src/stylesheets/datepicker';

.react-datepicker {
  border: none !important;
  box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);

  .react-datepicker__day--selected,
  .react-datepicker__day--in-selecting-range,
  .react-datepicker__day--in-range,
  .react-datepicker__month-text--selected,
  .react-datepicker__month-text--in-selecting-range,
  .react-datepicker__month-text--in-range,
  .react-datepicker__quarter-text--selected,
  .react-datepicker__quarter-text--in-selecting-range,
  .react-datepicker__quarter-text--in-range,
  .react-datepicker__year-text--selected,
  .react-datepicker__year-text--in-selecting-range,
  .react-datepicker__year-text--in-range,
  .react-datepicker__month--selected,
  .react-datepicker__month-text--keyboard-selected,
  .react-datepicker__day--keyboard-selected,
  .react-datepicker__month--range-start {
    background-color: $info-100;
  }

  .react-datepicker__header {
    background-color: $info-100;
  }

  .react-datepicker__day-name {
    color: $default-100;
    font-weight: bold;
  }

  .button-action {
    border-color: $default-100;
    background-color: transparent;

    &--left {
      margin-right: 5px;
    }

    &--right {
      margin-left: 5px;
    }

    &:hover {
      border-color: $default-100;
      background-color: $default-100;

      svg {
        color: $info-100;
      }
    }

    svg {
      color: $default-100;
    }
  }
}


