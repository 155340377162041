@import "../../../style/variables";
@import "../../../style/typography";
@import 'bootstrap/scss/bootstrap';

.radioInput {
  @extend .form-check;

  & input[type='radio'] {
    -webkit-appearance:none;
    width: 16px;
    height: 16px;
    border: 1px solid $default-500;
    border-radius: 16px;

    &:checked {
      -webkit-appearance:none;
      width: 16px;
      height: 16px;
      border: 1px solid $success-100;
      border-radius: 16px;

      &:before {
        background: $success-100;
      }
    }

    &:before {
      content:'';
      display: block;
      width: 60%;
      height: 60%;
      margin: 20% auto;
      border-radius: 50%;
    }
  }

  &--disabled {
    & input[type='radio'] {
      border: 1px solid $default-300 !important;

      &:checked {
        &:before {
          background: $default-300 !important;
        }
      }
    }
  }

  .form-check-label {
    padding: 0;
    margin: 0;
  }
}
