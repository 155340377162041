.wiki-menu {
  .edit-icon {
    color: #6BD098;
  }

  .delete-icon {
    color: #ef8157;
  }

  .page {
    font-size: 18px;
  }
}

#wiki_page_body {
  min-height: 300px;
}
