@import '../../style/variables';

.headerBlock {
  &--success {
    border-left: 2px solid $success-300;
  }

  &--warning {
    border-left: 2px solid $warning-100;
  }

  &--light {
    border-left: 2px solid $default-500;
  }

  &__text {
    &--success {
      color: $success-300 !important;
    }

    &--warning {
      color: $warning-100;
    }

    &--light {
      color: $default-500
    }
  }
}
