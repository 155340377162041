@import "../../../style/variables";
@import "../../../style/typography";

.ui-checkbox {
  display: inline-block;
  height: 24px;
  width: 24px;
  background: $white;
  border: 1px solid $default-400 !important;
  border-radius: 4px;
  margin-right: 16px;
  cursor: pointer;

  & path {
    fill: $white;
  }

  &--disabled {
    background: $default-200 !important;
    border: 1px solid $default-400 !important;
    cursor: not-allowed;

    & path {
      fill: $default-200 !important;
    }
  }

  &__wrapper {
    input[type="checkbox"] {
      -webkit-appearance: none;
      appearance: none;
      clip: rect(0 0 0 0);
      clip-path: inset(50%);
      height: 1px;
      display: none;
      position: absolute;
      white-space: nowrap;
      width: 1px;
    }
  }

  &__label {
    display: flex;
    font-family: 'Fira Sans';
    font-size: 16px;
    color: $default-800;
    margin-bottom: 0;

    &--required {
      color: $danger-100;
    }
  }
}

input:checked {
  + .checkIcon {
    &--success {
      @extend .ui-checkbox;
      background: $success-100;
      border: 1px solid $success-100;
    }

    &--danger {
      @extend .ui-checkbox;
      background: $danger-100;
      border: 1px solid $danger-100;
    }
  }

  + .squareIcon {
    &--success {
      & path {
        fill: $success-100 !important;
      }
    }

    &--danger {
      & path {
        fill: $danger-100 !important;
      }
    }
  }
}
