$muted-text-color: #AFB5C1;
$decor-color-1: #2F83D0;
$background-highlight-color: #F7F9FE;
$font-family-fira: 'Fira Sans', Arial, sans-serif;

.chart {
  width: 100%;
  height: 236px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between;
}


.chart {
  .chart__item {
    flex: 1;
    margin: 12px 10px 8px 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    color: $muted-text-color;
    font-size: 13px;
    line-height: 14px;
    text-align: center;
    font-family: $font-family-fira;
  }

  .chart__item {
    .chart__item__hours {
      font-weight: normal;
      margin-bottom: 8px;
    }

    .chart__item__box {
      background-color: $decor-color-1;
      border-radius: 5px;
      width: 100%;
    }

    .chart__item__box.zero-height {
      opacity: 0.1;
      height: 5px;
    }

    .chart__item__date {
      margin-top: 8px;
    }
  }
}
