@import "../../style/typography";

$marginHorizontalXSmall:   $spacingBase;                    // 4px
$marginHorizontalSmall:    $spacingBase * 2;                // 8px
$marginHorizontalNormal:   $spacingBase * 4;                // 16px
$marginHorizontalLarge:    $spacingBase * 5;                // 20px
$marginHorizontalXLarge:   $spacingBase * 6;                // 24px
$marginHorizontalXXLarge:  $spacingBase * 8;                // 32px
$marginHorizontalXXXLarge:  $spacingBase * 12;              // 48px

$marginVerticalXSmall:     $marginHorizontalXSmall;         // 4px
$marginVerticalSmall:      $marginHorizontalSmall;          // 8px
$marginVerticalNormal:     $marginHorizontalNormal;         // 16px
$marginVerticalLarge:      $marginHorizontalLarge;          // 20px
$marginVerticalXLarge:     $marginHorizontalXLarge;         // 24px
$marginVerticalXXLarge:    $marginHorizontalXXLarge;        // 32px
$marginVerticalXXXLarge:    $marginHorizontalXXXLarge;      // 48px

.margin {
  &__top {
    margin-top: $marginVerticalNormal;

    &--XSmall { margin-top: $marginVerticalXSmall; }
    &--Small { margin-top: $marginVerticalSmall; }
    &--Large { margin-top: $marginVerticalLarge; }
    &--XLarge { margin-top: $marginVerticalXLarge; }
    &--XXLarge { margin-top: $marginVerticalXXLarge; }
    &--XXXLarge { margin-top: $marginVerticalXXXLarge; }
  }

  &__bottom {
    margin-bottom: $marginVerticalNormal;

    &--XSmall { margin-bottom: $marginVerticalXSmall; }
    &--Small { margin-bottom: $marginVerticalSmall; }
    &--Large { margin-bottom: $marginVerticalLarge; }
    &--XLarge { margin-bottom: $marginVerticalXLarge; }
    &--XXLarge { margin-bottom: $marginVerticalXXLarge; }
    &--XXXLarge { margin-bottom: $marginVerticalXXXLarge; }
  }

  &__left {
    margin-left: $marginHorizontalNormal;

    &--XSmall { margin-left: $marginHorizontalXSmall; }
    &--Small { margin-left: $marginHorizontalSmall; }
    &--Large { margin-left: $marginHorizontalLarge; }
    &--XLarge { margin-left: $marginHorizontalXLarge; }
    &--XXLarge { margin-left: $marginHorizontalXXLarge; }
  }

  &__right {
    margin-right: $marginHorizontalNormal;

    &--XSmall { margin-right: $marginHorizontalXSmall; }
    &--Small { margin-right: $marginHorizontalSmall; }
    &--Large { margin-right: $marginHorizontalLarge; }
    &--XLarge { margin-right: $marginHorizontalXLarge; }
    &--XXLarge { margin-right: $marginHorizontalXXLarge; }
  }
}
