.card-day {
  width: 4.027vw;
  height: 4.027vw;
  min-width: 60px;
  min-height: 60px;
  border-radius: 4px;

  &_rest-day {
    color: #41ad58;
    background-color: #84cd9c80;
  }

  &_empty-regular {
    background-color: #E3E3E3;
  }

  &_regular {
    background-color: #FDD835;
  }

  &__day-num {
    margin: 1.6vw 1.87vw;
    display: inline-block;
    text-align: center;
    align-items: center;
    font-family: Helvetica Neue;
    font-style: normal;
    font-weight: bold;
    font-size: 3vw;
    line-height: 120%;
  }
}
