@import '../../ui-kit/style/variables';

.toaster {
  color: $white;

  svg {
    color: $white;
  }

  &--success {
    background-color: $success-200;
  }

  &--warning {
    background-color: $warning-200;
  }

  &--danger {
    background-color: $danger-200;
  }

  &--info {
    background-color: $info-100;
  }
}
