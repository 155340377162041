@import "../style/variables";

.width100 {
  width: 100% !important;
}

.width10 {
  width: 10% !important;
}

.width20 {
  width: 20% !important;
}

.width25 {
  width: 25% !important;
}

.width30 {
  width: 30% !important;
}

.width40 {
  width: 40% !important;
}

.width50 {
  width: 50% !important;
}

.width60 {
  width: 60% !important;
}

.width75 {
  width: 75% !important;
}

.height100 {
  height: 100% !important;
}

.mb0 {
  margin-bottom: 0 !important;
}

.marginAuto {
  margin: auto;
  margin-top: inherit;
  margin-bottom: inherit;
}

.width100px {
  width: 100px !important;
}

.width110px {
  width: 110px !important;
}

.width150px {
  width: 150px !important;
}

.height46 {
  height: 46px !important;
}

.maxWidth50rem {
  max-width: 50rem;
}

.minWidth150px {
  min-width: 150px;
}

.minHeight67px {
  min-height: 67px;
}

.cursorPointer {
  cursor: pointer;
}
