@import '../../style/variables';
@import "../../style/typography";

.ui-pagination {
  &--center {
    justify-content: center;
  }

  &--right {
    justify-content: end;
  }

  &--left {
    justify-content: start;
  }

  &__leftIcon {
    transform: rotate(180deg);
  }

  &__arrowButton {
    background-color: transparent;
    border: none;
    font-family: $font-family-secondary;
    font-weight: $font-weight-normal;
    font-size: $font-size-secondary;
    line-height: 21px;
    color: $default-600;
    margin-right: 12px;
    padding: 0;
    &--disabled {
      color: $default-400;

      & path {
        fill: $default-400;
      }
    }
  }

  &__pageButton {
    width: 30px;
    height: 30px;
    font-family: $font-family-secondary;
    font-weight: 400;
    font-size: $font-size-secondary;
    line-height: 21px;
    text-align: center;
    color: $default-800;
    background: transparent;
    border-radius: 40px;
    margin-right: 12px;
    border: none;

    &:hover,
    &:active,
    &:focus {
      background: $default-200;
    }

    &--active {
      font-weight: 700;
      color: $white;
      background: $link-200;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.15);

      &:hover,
      &:active,
      &:focus {
        background: $link-100;
      }
    }
  }
}
