.ui-widget {
  font-size: 12px;

  &-content {
    border: 1px solid #c5c5c5;
    background: #ffffff;
    color: #333333;
  }
}

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  outline: 0;
}

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;

  .ui-menu-item {
    padding: 10px;
    cursor: pointer;

    &:hover {
      background: #f4f3ef;
    }
  }
}

.ui-front {
  z-index: 100;
}
