@import '../../style/variables';

@mixin line-color($color) {
  svg {
    rect {
      stroke: $color;
    }
  }

  .ui-timeline__mark {
    &::before, &::after {
      background-color: $color;
    }
  }
}

.ui-timeline {
  width: 100%;

  &--success {
    @include line-color($success-100);
  }

  &--danger {
    @include line-color($danger-100);
  }

  &--dark {
    @include line-color($dark-100);
  }

  &--warning {
    @include line-color($warning-100);
  }

  &--primary {
    @include line-color($primary-100);
  }

  &__markLine {
    min-width: 7rem;
    max-width: 10rem;
  }

  svg {
    z-index: 1;
  }

  &__mark {
    position: relative;
    overflow: hidden;
    height: 100%;
    min-width: 26px;

    &--pending {
      svg {
        rect {
          stroke: $default-300;
        }
      }

      &::before, &::after {
        background-color: $default-300;
      }
    }
  }

  > :not(:first-child) {
    .ui-timeline__mark {
      &::before {
        position: absolute;
        content: '';
        left: 50%;
        transform: translate(-50%);
        height: 50%;
        width: 2px;
        top: 0;
      }
    }
  }

  > :not(:last-child) {
    .ui-timeline__mark {
      &::after {
        position: absolute;
        content: '';
        left: 50%;
        transform: translate(-50%);
        height: 50%;
        width: 2px;
        top: 50%;
      }
    }
  }
}
