@mixin flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin square($size, $radius: 0) {
  width: $size;
  height: $size;

  @if $radius != 0 {
    border-radius: $radius;
  }
}

@mixin make-avatar($color) {
  border-color: $color;

  &::before {
    background-color: rgba($color, 0.2);
  }
}
