// TODO: remove after refactoring Posts
.cover-image-with-text {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  min-height: 300px;
  color: $white-color;
}

.section-item {
  background-color: #f2f2f2;
  border-radius: $border-radius-base;
  z-index: 1;
  margin: 10px 1px;
}
