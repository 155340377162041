.search-form {
  .form-heading {
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.015em;
  }
}

.bootstrap-select.form-control.project_dropdown {
  margin-bottom: 16px;
}

.form-control.project_dropdown button.btn {
  padding: 10px 28px 10px 16px;
  max-height: 50px;
  height: 46px;
  margin-bottom: 0;
}

.form-control.project_dropdown button.btn.bs-placeholder, .project-col .form-control button.btn.bs-placeholder, .form-control.month-dropdown {
  text-transform: none;
  color: #7d7d7d;
  font-size: 16px;
  display: flex;
  align-items: center;

  &:after {
    width: 10px;
    height: 10px;
    background-image: url("../images/icons/arrow_down.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    flex-shrink: 0;
  }
}

.month-picker {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  cursor: pointer;
  input {
    caret-color: transparent;
    cursor: pointer;
  }

  .month-arrow {
    width: 10px;
    height: 10px;
    background-image: url("../images/icons/arrow_down.svg");
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    border: none;
    flex-shrink: 0;
    margin-left: 5px;

    &.focused {
      transform: rotate(180deg);
      transition: transform 150ms;
    }
  }

  input.form-control.month-dropdown {
    background-color: transparent;
    border: none;
    margin-right: 0;
    height: 26px;
    padding: 0;
    width: fit-content;
    max-width: 124px;
  }

}

.month-dropdown:focus ~ .month-picker:after {
  transform: rotate(180deg);
  background-color: red;
}


.btn-date-filter, .search-submit {
  border-radius: 4px;
  font-size: 16px;
  font-weight: 700;
  padding: 9.5px 22px;
  line-height: 18px;
  margin: 16px 0;

  &:first-child {
    margin-top: 6px;
  }

  &.btn-success {
    padding: 14px 22px;
    margin-bottom: 0;
  }
}

.card.card-form.card-form-search {
  max-width: 385px;
}

.card-body > .form-group:last-of-type {
  margin-bottom: 0;
}

.card-body .form-control {
  height: auto;
}

.btns-label {
  letter-spacing: 0.015em;
  font-size: 16px;
  line-height: 120%;
  color: #B6B6B6;
  margin-top: 16px;
}

.tt-alert {
  display: flex;
  flex-direction: column;
  align-items: center;
  color: $success-color;
  font-size: 20px;
  padding-top: 30px;
  padding-bottom: 30px;

  &.delete-success {
    .hooray-img {
      width: 108px;
      height: 108px;
      background-image: url("../images/hooray_success.svg");
      flex-shrink: 0;
      margin-bottom: 18px;
    }

    .items-count-text {
      font-weight: bold;
    }
  }
  &.log-success {
    padding-top: 43px;
    padding-bottom: 67px;
    color: #5F9D42;
    .time-img {
      width: 200px;
      height: 162px;
      background-image: url("../images/log-clock.svg");
      flex-shrink: 0;
      margin-bottom: 11px;
    }
    .items-count-text {
      font-weight: 700;
    }
  }
}



@media (min-width: 768px) {
  .dropdown {
    .datepicker-dropdown {
      margin: 0;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      text-transform: none;

      .datepicker-placeholder{
        color: #66615b !important;
      }

      &::after {
        margin-left: 5px;
      }
    }

    .dropdown-datepicker-menu {
      min-width: 380px;
      padding: 10px 20px;
    }
  }
}


.card.card-search {
  padding-bottom: 1rem;

  .card-title {
    padding: 5px 15px 0 15px;
  }

  #search-form {
    padding: 0 15px;
  }

  .form-element {
    @include if-smaller-than('md') {
      &:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    &:not(:last-child) {
      margin-right: 20px;
    }

    &-grow2 {
      flex-grow: 1;
    }

    &-grow1 {
      flex-grow: 1;
    }

    &.dates {
      max-width: 194px;
    }

    .datepicker-dropdown {
      margin: 0;
    }

    .form-group {
      margin: 0;
    }
  }

}

#search-form {
  margin-top: 15px;

  .form-control {
    height: 46px;
  }

  form {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .btn-clear {
      width: 120px;
    }

    .btn-search {
      width: 210px;
    }

    @include if-smaller-than('md') {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
      .btn-clear {
        width: 100%;
      }
      .btn-search {
        width: 100%;
      }
      .form-element {
        width: 100%;

        &.dates {
          max-width: 100%;
        }
      }
    }
  }
}

.overview-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  margin-top: 8px;
  flex-wrap: wrap;
  margin-left: auto;
  align-items: center;
  @include if-smaller-than('sm') {
    flex-direction: column;
  }

  .btns-container {
    display: flex;
    @include if-smaller-than('sm') {
      flex-direction: column;
    }
  }

  .btn-delete {
    border-radius: 4px;
  }

  .btn-log-month {
    margin-right: 28px;
    @include if-smaller-than('sm') {
      margin-right: 0;
      margin-bottom: 28px;
    }
  }
}

.btn-group:not(.bootstrap-select) {
  display: flex;
  flex-wrap: nowrap;
  overflow: hidden;
  justify-content: flex-end;

  .btn-action {
    border: 1px solid $black-color;
    border-radius: 3px;
    background-color: transparent;
    width: 30px;
    height: 30px;
    min-width: 30px;
    flex-shrink: 0;
    padding: 0;
    display: flex;
    background-position: center;
    background-repeat: no-repeat;
    margin-top: 0;

    &.btn {
      &-copy {
        background-image: url('../images/icons/copy.svg');
        border-color: #c4c4c4;
      }

      &-edit {
        background-image: url('../images/icons/edit.svg');
        border-color: $success-color;
      }

      &-delete {
        background-image: url('../images/icons/cross.svg');
        border-color: $danger-color;
      }
    }

    &.btn-block:not(:last-child) {
      margin-right: 3px;
    }
  }
}

.time-tracking {
  .dropdown .dropdown-datepicker-menu {
    width: 330px;
    max-width: 330px;
    min-width: 300px;
  }

  .card .card-header {
    padding-top: 0;

    &:after {
      content: '';
      position: absolute;
      height: 1px;
      background-color: #e9e9e9;
      width: 100%;
      top: 53px;
      left: 0;
      right: 0;
    }

    .card-title {
      margin-top: 0;
      padding-top: 5px;
      padding-bottom: 15px;
    }
  }

  .person-projects, .project-members {
    max-height: 416px;
    overflow: hidden;
    font-family: $font-family-fira;
    @include if-bigger-than('md') {
      height: calc(100% - 20px);
    }
    @include if-smaller-than('lg') {
      max-height: 304px;
      height: calc(100% - 20px);
    }

    .card-body {
      overflow: auto;
    }
    .card-body table {
      overflow: auto;
    }

    td {
      border: none;
    }

    .text-right {
      font-weight: normal;
    }
  }

  .project-members {
    .team-members-table {
      margin-bottom: 0;
    }

    .photo-td {
      padding-left: 0;
      width: calc(46px + 7px + 7px);

      .user .photo {
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;

        img {
          width: 46px;
          height: 46px;
          border-radius: 50%;
          margin-right: 0;
        }
      }
    }

    .team-member-cell-text.text-right {
      font-size: 18px;
      line-height: 18px;
      font-weight: 700;
    }

    .team-member-cell-text.secondary.text-right {
      font-size: 13px;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .person-projects td.photo-td {
    width: 46px;
    .project {
      width: 46px;
      height: 46px;
      border-radius: 50%;
      background-color: #FCE5C4;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      .logo {
        font-family: 'Helvetica Neue', Arial, sans-serif;
        font-weight: bold;
        font-size: 16px;
        line-height: 20px;
        text-transform: uppercase;
        color: #ECAB3E;
      }
    }
  }

  .card-issued-equipments.summary {
    overflow: hidden;

    .separator {
      width: 1px;
      background-color: #e9e9e9;
      margin: 0 5px;
      flex-shrink: 0;
    }

    .card-body {
      padding-top: 0;
      padding-bottom: 0;

      & > div {
        @include if-smaller-than('sm') {
          flex-wrap: wrap;
        }
      }
    }

    .card-category {
      padding: 15px 0;
      width: 100%;
      display: flex;
      justify-content: center;
      color: #000;

      tr {
        vertical-align: baseline;
      }

      th {
        padding-right: 5px;
        font-family: $font-family-roboto;
        font-size: 20px;
      }

      td {
        font-family: $font-family-fira;
        font-size: 14px;
      }
    }
  }

  .tablet-summary {
    @include if-smaller-than('sm') {
      display: none;
    }
    @include if-bigger-than('lg') {
      display: none;
    }
  }

  .not-tablet-summary {
    @include if-between('sm', 'lg') {
      display: none;
    }
  }

  .card.card-issued-equipments.detailed-report {
    padding: 0;
    overflow: auto;

    .card-body {
      padding: 0;
    }

    table {
      & > thead > tr > th {
        color: #1c1c1c;
        padding-top: 30px;
        font-family: $font-family-roboto;

        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          padding-right: 30px;
        }

        & > a {
          color: #1c1c1c;
        }
      }

      tr.selected {
        background-color: #F2F8F4;
      }

      & > tbody > tr > td {
        vertical-align: top;
        font-family: $font-family-fira;

        &:first-child {
          padding-left: 30px;
        }

        &:last-child {
          width: 125px;
          padding-right: 30px;
        }

        &.cell-person {
          font-weight: 700;
          font-size: 14px;
          width: 180px;
        }

        &.checkbox-cell {
          width: 16px;
        }

        &.cell-project {
          width: 200px;

          a {
            font-size: 13px;
          }
        }

        &.cell-description {
          color: #000;
          font-weight: normal;
        }

        &.cell-duration {
          width: 200px;

          .duration-list {
            list-style: none;
            padding: 0;
            margin: 0;

            & > li {
              padding-left: 23px;
              color: #b6b6b6;
              position: relative;
              font-size: 16px;
              line-height: 1.3;

              &:not(.duration-date) {
                &:before {
                  content: '';
                  width: 9px;
                  height: 9px;
                  display: block;
                  background: url('../images/icons/simple_bullet.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  position: absolute;
                  left: calc(16px / 2 - 7px / 2);
                  top: calc(50% - 7px / 2);
                }
              }

              &.duration-date {
                &:before {
                  content: '';
                  width: 20px;
                  height: 16px;
                  display: block;
                  background: url('../images/icons/calendar.svg');
                  background-repeat: no-repeat;
                  background-position: center;
                  position: absolute;
                  left: 0;
                  top: calc(50% - 16px / 2);
                }
              }

              &.duration-hours {
                color: #000;
                font-size: 14px;
              }
            }
          }
        }

        &.cell-actions {
          width: 100px;
        }
      }
    }
  }

  .overview-header {
    min-height: 44px;

    .export-block {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      margin-left: 30px;

      span {
        margin-right: 20px;
        font-weight: 400;
        font-size: 14px;
        color: #7b7b7b;
        font-family: $font-family-fira;
      }

      .pdf-dropdown.dropdown {
        margin-right: 15px;

        a.dropdown-item {
          color: $link-color;
          font-size: 14px;
          line-height: 18px;
          padding-top: 15px;
          padding-bottom: 15px;
          padding-left: 30px;
          font-family: $font-family-fira;
        }

        .btn {
          height: 37px;
          font-size: 16px;
          line-height: 18px;
          font-family: $font-family-montserrat;

          &.dropdown-toggle {
            padding-right: 42.5px;

            &:after {
              background-image: url('../images/icons/big-arrow.svg');
              width: 20px;
              height: 12.5px;
              right: 12.5px;
              transform: rotate(180deg);
              top: calc(50% - 6px);
            }

            &[aria-expanded="true"]:after {
              transform: rotate(0deg);
            }
          }
        }
      }

      .btn.csv-btn.btn-outline-info {
        height: 37px;
        padding-top: 0;
        padding-bottom: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          font-size: 16px;
          line-height: 18px;
        }

        &:hover {
          a {
            color: $white-color !important;
          }
        }
      }
    }
  }
}

.main-heading {
  display: flex;
  align-items: baseline;
  flex-wrap: wrap;

  .month-picker {
    margin-left: 27px;
  }

  @media screen and (max-width: 514px) {
    flex-direction: column;
    .month-picker {
      margin-left: 0;
    }
  }
}

.mass-tracking {
  margin-top: 30px;

  .time-trackings-daily-data {
    .card {
      padding: 30px;
      @include if-smaller-than('xs') {
        padding: 15px;
      }

      .card-body {
        padding: 0;

        .day-content {
          display: flex;
          flex-direction: column;
        }

        .day-tracks-list {
          display: flex;
          //margin-bottom: 7px;

          .day-block {
            flex-shrink: 0;

            .card-day__day-num {
              margin: 0;
              padding: 10px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              flex-wrap: nowrap;
              font-family: $font-family-roboto;
              width: 100%;
              height: 100%;

              .num {
                font-size: 28px;
                font-weight: bold;
                line-height: 18px;
                margin-bottom: 6px;
              }

              .name {
                font-size: 14px;
                text-transform: uppercase;
                line-height: 18px;
              }
            }
          }

          .daily-table {
            width: 100%;
            margin-left: 30px;

            th {
              padding-top: 0;
              border-bottom: 1px solid #D0D0D0;
              padding-bottom: 17px;
              font-family: $font-family-roboto;
            }

            td {
              padding-top: 15px;
              padding-bottom: 15px;
              font-family: $font-family-fira;

              &.description {
                font-family: $font-family-roboto;
                color: #000;
              }

              &.duration {
                .time-duration {
                  color: #AFB5C1;
                }
              }
            }

            tr.selected {
              color:$link-color;
              td {
                color:$link-color;
                &.description {
                  color:$link-color;
                }

                &.duration {
                  .time-duration {
                    color:$link-color;
                  }
                }
              }
            }

            table {
              margin-bottom: 0;
            }

            .empty-state-label {
              font-weight: 500;
              font-size: 16px;
              line-height: 18px;
              color: #B6B6B6;
              font-family: $font-family-fira;
              margin: 20px 0;
            }

            .btn-group {
              padding-right: 0;
            }
          }

          @include if-smaller-than('sm') {
            flex-direction: column;
            .daily-table {
              margin-left: 0;
              margin-top: 15px;

              .btn-group {
                flex-direction: column;
                align-items: flex-end;

                .btn-action.btn-block:not(:last-child) {
                  margin-right: 0;
                  margin-bottom: 3px;
                }
              }
            }

            .card-day {
              width: 100%;
            }
          }
          @include if-smaller-than('xs') {
            .daily-table {
              table {
                font-size: 10px;

                th {
                  font-size: 10px;
                }
              }

              .empty-state-label {
                font-size: 12px;
              }
            }
          }
        }

        .add-time-form {
          border-top: 1px solid #D0D0D0;

          .form-heading {
            color: #8f8f8f;
            margin-top: 7px;
            margin-bottom: 9px;
            line-height: 18px;
            font-family: $font-family-fira;
            font-size: 16px;
          }

          form {
            display: flex;
            justify-content: space-between;
            flex-wrap: nowrap;
            width: 100%;

            .form-control {
              height: 46px;
              overflow: visible;
            }

            .form-group {
              margin-bottom: 0;
            }

            & > * {
              flex-shrink: 1;
              flex-grow: 0;
              &:not(:last-child) {
                margin-right: 2.08vw;
              }
            }

            .btns-container {
              flex-wrap: nowrap;
              display: flex;
              max-width: 11.02vw;
              min-width: fit-content;
              &>div:not(:last-child) {
                margin-right: 2.08vw;
              }

              @include if-smaller-than('sm') {
                justify-content: center;
              }
            }

            .project-col {
              width: 20.56vw;
              min-width: 11vw;

              .bootstrap-select.form-control .btn {
                max-height: 46px;
                padding-left: 20px;
              }
            }

            .start-time-col, .end-time-col {
              width: 12.5vw;
              height: 46px;
              min-width: 14.5vw;
            }

            .description-col {
              width: 25.28vw;
              min-width: 10vw;

              textarea {
                height: 46px;
                resize: vertical;
                padding: 14px 10px 14px 20px;
                font-size: 16px;
                line-height: 16px;
                color: #000;
                min-height: 46px;
              }
            }

            .btn-save, .btn-cancel {
              max-width: 5.56vw;
              min-width: fit-content;
              flex-grow: 1;

              .btn {
                height: 46px;
              }

              @include if-smaller-than('sm') {
                max-width: 100%;
                .btn {
                  margin: 0 auto;
                  display: block;
                }
              }
            }

            @include if-smaller-than('sm') {
              flex-direction: column;
              :not(:last-child){
                margin-bottom: 10px;
              }
              .project-col, .start-time-col, .end-time-col, .description-col, .btn-save {
                width: 100%;
              }
            }


            &.is-editing {
              .btn, .form-control {
                border-color: $link-color !important;
              }
            }
          }
        }
      }
    }
  }
}

.swal2-buttonswrapper button:not(:last-child) {
  margin-right: 1.5em;
}

#new_time_tracking .form-bordered input[type=submit] {
  margin-right:1.5em;
}



.tt-modal {
  min-width: 520px;
  line-height: 1.2;
  font-size: 20px;
  padding: 15px;
  @include if-smaller-than(sm) {
    min-width: 300px;
  }

  form {
    font-weight: 400;
    display: flex;
  }
  .inner-container {
    padding:0 15px;
    flex-wrap: nowrap;
    &>.form-group{
      width: 50%;
      &:first-child {
        margin-right: 20px;
      }
      .form-group {
        margin-bottom: 0;
        width: 100%;
        .form-control {
          height: 46px;
        }
      }
    }
    input.btn {
      margin-right: 18px;
    }
  }
}
