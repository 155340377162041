.table{

    thead.underline {
      border-bottom: 2px solid lightgray;
    }

    .img-wrapper{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      margin: 0 auto;
    }

    .img-row{
      max-width: 60px;
      width: 60px;
    }

    .form-check{
        margin: 0;

        & label .form-check-sign::before,
        & label .form-check-sign::after{
            top: -17px;
            left: 4px;
        }
    }

    .btn{
        margin: 0;
        min-width: 100%;
    }

    .btn-fab {
      min-width: 10%;
    }

    .btn-inline {
      min-width: 10%
    }

    .actions {
      .btn {
        margin-right: 1rem;
      }
    }

    small,.small{
      font-weight: 300;
    }

   > thead > tr > th{
       font-size: 14px;
       font-weight: $font-weight-bold;
       padding-bottom: 0;
       text-transform: uppercase;
       border: 0;
   }

   .radio,
   .checkbox{
       margin-top: 0;
       margin-bottom: 0;
       padding: 0;
       width: 15px;

       .icons{
           position: relative;
       }

        label{
            &:after,
            &:before{
                top: -17px;
                left: -3px;
            }
        }
   }
   > thead > tr > th,
   > tbody > tr > th,
   > tfoot > tr > th,
   > thead > tr > td,
   > tbody > tr > td,
   > tfoot > tr > td{
       padding: 12px 7px;
       vertical-align: middle;
   }

   .th-description{
       max-width: 150px;
   }
   .td-price{
       font-size: 26px;
       font-weight: $font-weight-light;
       margin-top: 5px;
       position: relative;
       top: 4px;
       text-align: right;
   }
   .td-total{
        font-weight: $font-weight-bold;
        font-size: $font-size-h5;
        padding-top: 20px;
        text-align: right;
    }

   .td-actions .btn{
    margin: 0px;
    }

    > tbody > tr{
        position: relative;
    }
  .list-for-links {
    display: block;
  }
  .success-row {
    background-color: $table-row-success;
  }
}

.table-shopping{
    > thead > tr > th{
        font-size: $font-size-h6;
        text-transform: uppercase;
    }
    > tbody > tr > td{
        font-size: $font-paragraph;

        b{
            display: block;
            margin-bottom: 5px;
        }
    }
    .td-name{
        font-weight: $font-weight-normal;
        font-size: 1.5em;
        small{
            color: $dark-gray;
            font-size: 0.75em;
            font-weight: $font-weight-light;
        }
    }
    .td-number{
       font-weight: $font-weight-light;
       font-size: $font-size-h4;
   }
    .td-name{
        min-width: 200px;
    }
    .td-number{
        text-align: right;
        min-width: 170px;

        small{
            margin-right: 3px;
        }
    }

    .img-container{
        width: 120px;
        max-height: 160px;
        overflow: hidden;
        display: block;

        img{
            width: 100%;
        }
    }
}

.table-responsive{
  overflow: scroll;
  padding-bottom: 10px;
}

#tables .table-responsive{
    margin-bottom: 30px;
}

.table-hover>tbody>tr:hover{
  background-color: #f5f5f5;
}

.pagination {
  font-family: Helvetica;
  text-align: center;
  padding: 1em;
  cursor: default;
  display: flex;
  align-items: center;

  a, span {
    padding: 1rem 1rem;
  }

  .disabled {
    color: #aaaaaa;
  }

  .current {
    font-style: normal;
    font-weight: bold;
    background-color: $info-color;
    box-shadow: 0 2px 2px hsla(38,16%,76%,.5);
    display: inline-block;
    width: 1.7em;
    height: 1.7em;
    line-height: 1.5;
    -moz-border-radius: 1em;
    -webkit-border-radius: 1em;
    border-radius: 1em;
    color: $white-color;
    padding-top: 2px;
  }

  a {
    text-decoration: none;
    color: $black-color;

    &:hover, &:focus {
      text-decoration: underline;
    }
  }
}

.team-members-table {
  max-height: 430px;
  overflow-y: scroll;

  .team-member-cell-text {
    font-size: 16px;
    line-height: 20px;
    font-weight: bold;

    &.secondary {
      font-size: 13px;
      line-height: 16px;
      font-weight: normal;
    }
  }
}
