@import '../../style/variables';
@import '../../style/typography';
@import 'bootstrap/scss/bootstrap';

@mixin badge-color($color) {
  border-color: $color;
  background-color: $color;
  color: $white !important;
}

.ui-badge {
  @extend .badge;
  text-transform: none;
  padding: 4px 14px !important;
  max-width: max-content !important;
  font-weight: $font-weight-normal !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center;

  &--pill {
    @extend .badge-pill;
  }

  // colors
  &--success {
    @extend .badge-success;
    @include badge-color($success-100);
  }

  &--warning {
    @extend .badge-warning;
    @include badge-color($warning-100);
  }

  &--danger {
    @extend .badge-danger;
    @include badge-color($danger-100);
  }

  &--dark {
    @extend .badge-dark;
    @include badge-color($dark-100);
  }

  &--info {
    color: $tags-blue;
    background-color: $tags-background-blue;
  }

  &--secondary {
    color: $tags-orange;
    background-color: $tags-background-orange;
  }

  &--pink {
    color: $tags-pink;
    background-color: $tags-background-pink;
  }

  &--purple {
    color: $tags-purple;
    background-color: $tags-background-purple;
  }

  &--primary {
    @extend .badge-success;
    @include badge-color($info-100);
  }

  &--transparent {
    color: transparent;
    background-color: transparent;
  }


  //sizes
  &--xxsmall {
    font-size: $fontSizeXXSMALL !important;
  }

  &--xsmall {
    font-size: $fontSizeXSMALL !important;
  }

  &--small {
    font-size: $fontSizeSMALL !important;
  }

  &--normal {
    font-size: $fontSizeNormal !important;
  }

  &--large {
    font-size: $fontSizeLarge !important;
  }

  &--xlarge {
    font-size: $fontSizeXLarge !important;
  }

  &--xxlarge {
    font-size: $fontSizeXXLarge !important;
  }

  &--description {
    font-size: $font-size-description !important;
  }

  &--fullWidth {
    max-width: none !important;
    width: 100%;
  }
}
