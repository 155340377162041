@import '../../style/typography';
@import '../../style/variables';
@import "../../style/mixins";

.ui-avatar {
  border-radius: 50%;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;

  &--small {
    width: 24px;
    height: 24px;
    min-width: 24px;
  }

  &--normal {
    width: 32px;
    min-width: 32px;
    height: 32px;
  }

  &--large {
    width: 136px;
    height: 136px;
    min-width: 136px;
  }

  &__wrapper {
    position: relative;
  }

  &__bubble {
    padding: 8px;
    box-shadow: 0px -3px 33px 0px rgba(243, 199, 22, 0.30);
    border-radius: 56px;
    background-color: #FFE600;
    font-family: $font-family-secondary;
    font-size: 13px;
    font-weight: 400;
    color: $black;

    &Wrapper {
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  &__secondary {
    border: 1px solid;
    opacity: 1;
    position: relative;
    z-index: 1;

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 50%;
      z-index: -1;
    }

    &--success {
      @include make-avatar($success-300);
    }

    &--warning {
      @include make-avatar($warning-300);
    }

    &--danger {
      @include make-avatar($danger-100);
    }

    &--info {
      @include make-avatar($info-100);
    }

    &--pending {
      opacity: 0.5;
    }

    &Icon {
      position: absolute;
      top: 0;
      right: -4px;
      width: 12px;
      height: 12px;
    }
  }
}
