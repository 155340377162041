@import '../../../../ui-kit/style/typography';
@import '../../../../ui-kit/style/variables';

.achievementsHeader {
  &__indexHeader {
    background-color: #212123;
    color: $white;
    padding: 24px 24px;
    border-radius: 12px;
  }

  &__otherYearsHeader {
    background-image: url('../../javascript/ui-kit/assets/images/achievementsHeader.svg');
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right;
  }

  &__currentYearHeader {
    background-image: url('../../javascript/ui-kit/assets/images/austronautOne.svg'),
    url('../../javascript/ui-kit/assets/images/austronautTwo.svg'),
    url('../../javascript/ui-kit/assets/images/achievementsHeader.svg');
    background-size: contain
    contain
    cover;
    background-repeat: no-repeat,
    no-repeat,
    no-repeat;
    background-position: bottom 0 right 190px,
    bottom 0 right 30px,
    bottom 0 right -1px;
  }
}
