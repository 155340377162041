@import './admin/mixins';

.card.card-search {
  #search-form form {
    .btn-search {
      align-self: flex-end;
    }

    .form-element {
      .bootstrap-select.form-control .btn {
        max-height: 46px;
        padding-left: 20px;
      }
    }

    @include if-smaller-than('md') {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;

      .btn-search {
        width: 100%;
      }

      .form-element {
        width: 100%;
      }
    }
  }
}
