@import '../../../style/variables';

.ui-fileInput {
  cursor: pointer;

  &__icon {
    margin-right: 4px;

    svg {
      color: $link-100;
    }
  }

  &__btnLabel {
    color: $link-100 !important;
  }

  &__label {
    display: flex;
    max-width: 100%;
  }

  &:hover, &:focus {
    .ui-fileInput__icon {
      svg {
        color: $link-200;
      }
    }

    .ui-fileInput__btnLabel {
      color: $link-200 !important;
    }
  }

  &--disabled {
    cursor: not-allowed;

    .ui-fileInput__icon {
      svg {
        color: $default-400;
      }
    }

    .ui-fileInput__btnLabel {
      color: $default-400 !important;
    }
  }

  &--overflowHidden {
    max-width: 100%;
  }

  &Secondary {
    cursor: pointer;
    background-color: rgba($success-100, 0.2);
    border: $success-200 1px dashed;
    border-radius: 2px;
    width: 100%;

    &__title {
      font-weight: bold;
      text-decoration: underline
    }

    &--disabled {
      cursor: not-allowed;
      background-color: rgba($default-400, 0.2);
      border: $default-400 1px dashed;

      .ui-fileInputSecondary__title, svg {
        color: $default-400;
      }
    }
  }
}
