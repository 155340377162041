.photo-small{
  width: 50px;
  height: 50px;
  border-radius: 50%;
  overflow: hidden;

  img{
    width: 100%;
  }
}
