@import '../../style/variables';
@import '../../style/typography';

.ui-tooltip {
  max-width: max-content;
  width: 140px;
  padding: 8px;
  border-radius: 8px;
  font-family: $font-family-secondary;
  font-weight: $font-weight-normal;
  font-size: $font-size-description;
  z-index: 1060;

  &--dark {
    color: $white;
    background-color: $default-700;
  }

  &--light {
    color: $default-800;
    background-color: $white;
  }

  &__children {
    &--onlyHover {
      &:hover {
        pointer-events: none;
      }
    }
  }
}
