@import '../../../style/variables';
@import '../../../style/typography';

.textAreaInput {
  font-style: normal;
  font-weight: normal;
  width: 100%;
  min-height: 46px;
  padding: 11px 8px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $default-300;
  color: $black;
  caret-color: $link-200;
  font-size: $font-size-primary;
  font-family: $font-family-secondary;
  line-height: 22px;

  &::placeholder {
    color: $default-300;
  }

  &:focus,
  &:active {
    outline: none;
    border: 1px solid $default-400;
  }

  &--error {
    border-color: $danger-100;
  }

  &--disabled {
    background-color: $default-200;
    color: $default-600;
    cursor: not-allowed;
    border: 1px solid $default-300;
  }

  &--noResize {
    resize: none;
  }

  &__wrapper {
    position: relative;
  }

  &__characterCounter {
    position: absolute;
    bottom: 1rem;
    right: 1rem;
    color: $default-300;

    &--active {
      color: $default-500;
    }

    &--error {
      color: $danger-100;
    }
  }
}
