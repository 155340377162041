@import '../../../style/variables';
@import '../../../style/typography';

.list {
    list-style: none;
    margin: 0;
    padding: 0;

  &__asteriks {
    color: $red;
  }
}
