@import '../../../javascript/ui-kit/style/variables';

.breadCrumbs {
  border-bottom: 1px solid $default-300;
  width: calc(100% + 60px);
  transform: translateX(-30px);
  padding: 0 30px;

  &--hasAvatar > div:first-child {
    transform: translateY(-7px);
  }

  &__step {
    cursor: pointer;
  }

  &__line {
    margin: 0 16px;
    height: 1px;
    width: 40px;
    background-color: $default-400;
  }
}
