@import '../../style/typography';
@import '../../style/variables';

.dropdown {
  &__menu {
    background-color: $white;
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    overflow: hidden;
  }

  &__button {
    border: none;
    background-color: transparent;
    padding: 4px 16px;
    font-family: $font-family-secondary;
    font-size: $font-size-secondary;
    color: $default-800 !important;
    text-align: left;

    &:hover,
    &:focus,
    &:active {
      background-color: $default-200;
    }

    &-disabled {
      color: $default-500 !important;

      &:hover,
      &:focus,
      &:active {
        background-color: transparent;
      }
    }
  }
}
