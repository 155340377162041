@import "../../../style/variables";
@import "../../../style/typography";

.ui-datePicker {
  min-height: unset;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid $default-300 !important;
  color: $default-800;
  font-size: $font-size-primary;
  font-family: $font-family-secondary;
  font-style: normal;
  font-weight: normal;
  line-height: 24px;
  box-shadow: none;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    outline: none;
    border: 1px solid $default-400 !important;
  }

  &--disabled {
    background-color: $default-200;
    cursor: not-allowed;
    border: 1px solid $default-300 !important;
  }

  &--error {
    border-color: $danger-100 !important;
  }

  &__inputWrapper {
    height: 46px;
    padding: 11px 8px;
  }
}

.react-datepicker-popper {
  z-index: 10 !important;
}

.react-datepicker__close-icon::after {
  background-color: white !important;
  //content: url('../../../assets/images/clear-icon.svg') !important;
  outline: none;
}
