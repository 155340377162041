@import '../../style/variables';
@import '../../style/typography';

.inputWrapper {
  &__label {
    display: inline-block;
    margin: 4px 0 8px;
    font-style: normal;
    font-family: $font-family-secondary;
    color: $default-700;

    &--large {
      font-weight: $font-weight-bold;
      font-size: $font-size-primary;
      line-height: 21px;
    }

    &--small {
      font-weight: $font-weight-normal;
      font-size: $font-size-description;
      line-height: 16px;
    }

    &--required {
      color: $danger-100;
    }
  }

  &__error {
    display: inline-block;
    margin: 8px 0;
    font-style: normal;
    font-weight: normal;
    color: $danger-100;
    font-family: $font-family-secondary;
    font-size: $font-size-secondary;
    line-height: $font-size-primary;
  }

  &__info {
    display: inline-block;
    font-family: 'Fira Sans';
    font-weight: 400;
    font-size: 13px;
    line-height: 16px;
    color: #8F8F8F;
    margin-left: 4px;
  }
}
