.from-check,
.form-check-radio,
.calendar-check {
    margin-bottom: 12px;
    position: relative;
}

.form-check {
  padding-left: 0;
  margin-bottom: .5rem;

    .form-check-label{
        display: inline-block;
        position: relative;
        cursor: pointer;
        padding-left: 35px;
        line-height: 26px;
        margin-bottom: 0;
        margin-top: 1.1rem;
    }

    .form-check-sign::before,
    .form-check-sign::after {
        content: " ";
        display: inline-block;
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        cursor: pointer;
        border-radius: 6px;
        background-color: #AAA7A4;
        -webkit-transition: opacity 0.3s linear;
        -moz-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        transition: opacity 0.3s linear;
    }
    .form-check-sign::after {
        font-family: 'FontAwesome';
        content: "\f00c";
        text-align: center;
        font-size: 15px;
        opacity: 0;
        color: #FFF;
        border: 0;
        background-color: inherit;
    }
    &.disabled{
        .form-check-label{
            color: $dark-gray;
            opacity: .5;
            cursor: not-allowed;
        }
    }

}

.calendar-check {
  .card &-label{
    &-holiday,
    &-birthdays,
    &-action-points,
    &-reviews,
    &-vacations{
      font-size: 16px;
      display: inline-block;
      position: relative;
      cursor: pointer;
      padding-left: 28px;
      padding-right: 5px;
      line-height: 26px;
      margin-bottom: 0;

      &::before,
      &::after {
        content: " ";
        display: inline-block;
        position: absolute;
        width: 24px;
        height: 24px;
        left: 0;
        cursor: pointer;
        border-radius: 6px;
        background-color: #9A9A9A;
        -webkit-transition: opacity 0.3s linear;
        -moz-transition: opacity 0.3s linear;
        -o-transition: opacity 0.3s linear;
        -ms-transition: opacity 0.3s linear;
        transition: opacity 0.3s linear;
      }
      &::after {
        font-family: 'FontAwesome';
        content: "\f00c";
        text-align: center;
        font-size: 15px;
        opacity: 0;
        color: #FFF;
        border: 0;
        background-color: inherit;
      }
    }
  }
}



.form-check.disabled .form-check-label,
.form-check.disabled .form-check-label {

}

.form-check input[type="checkbox"],
.form-check-radio input[type="radio"],
.calendar-check input[type="checkbox"]{
    opacity: 0;
    position: absolute;
    visibility: hidden;
}

.form-check input[type="checkbox"]:checked + .form-check-sign::after,
.calendar-check input[type="checkbox"]:checked + .calendar-check-label-holiday::after,
.calendar-check input[type="checkbox"]:checked + .calendar-check-label-birthdays::after,
.calendar-check input[type="checkbox"]:checked + .calendar-check-label-action-points::after,
.calendar-check input[type="checkbox"]:checked + .calendar-check-label-reviews::after,
.calendar-check input[type="checkbox"]:checked + .calendar-check-label-vacations::after{
    opacity: 1;
}

.form-control input[type="checkbox"]:disabled + .form-check-sign::before,
.checkbox input[type="checkbox"]:disabled + .form-check-sign::after{
    cursor: not-allowed;
}

.form-check .form-check-label input[type="checkbox"]:disabled + .form-check-sign,
.form-check-radio input[type="radio"]:disabled + .form-check-sign{
    pointer-events: none !important;
}

.form-check-radio{
  margin-left: -3px;

    .form-check-label{
        padding-left: 2rem;
    }
    &.disabled{
        .form-check-label{
            color: $dark-gray;
            opacity: .5;
            cursor: not-allowed;
        }
    }
}

.form-check-radio .form-check-sign::before{
    font-family: 'FontAwesome';
    content: "\f10c";
    font-size: 22px;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: inline-block;
    position: absolute;
    opacity: .50;
    left: 5px;
    top: -5px;
}

.form-check-label input[type="checkbox"]:checked + .form-check-sign:before {
    background-color: #66615B;
}

.calendar-check input[type="checkbox"]:checked + .calendar-check-label-holiday:before {
   background-color: #e49b7d;
 }

.calendar-check input[type="checkbox"]:checked + .calendar-check-label-birthdays:before {
  background-color: #d6ba7f;
}

.calendar-check input[type="checkbox"]:checked + .calendar-check-label-action-points:before {
  background-color: #99e2b9;
}

.calendar-check input[type="checkbox"]:checked + .calendar-check-label-reviews:before {
  background-color: #b19cde;
}

.calendar-check input[type="checkbox"]:checked + .calendar-check-label-vacations:before {
  background-color: #a4d0e0;
}

.form-check-radio input[type="radio"] + .form-check-sign:after,
.form-check-radio input[type="radio"] {
    opacity: 0;
    @include transition-opacity(0.3s, linear);
    content:" ";
    display: block;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after {
    font-family: 'FontAwesome';
    content: "\f192";
    top: -5px;
    position: absolute;
    left: 5px;
    opacity: 1;
    font-size: 22px;
}

.form-check-radio input[type="radio"]:checked + .form-check-sign::after{
    opacity: 1;
}


.form-check-radio input[type="radio"]:disabled + .form-check-sign::before,
.form-check-radio input[type="radio"]:disabled + .form-check-sign::after {
    color: $dark-gray;
}

.badgebox {
  opacity: 0;

  & + .badge {
    text-indent: -999999px;
    width: 27px;
  }

  &:focus + .badge {
    box-shadow: inset 0px 0px 5px;
  }

  &:checked + .badge {
    text-indent: 0;
  }
}
