@import '../../style/variables';

.tabs {
  width: 100%;
  display: flex;
  border: 1px solid $border-main;
  background-color: $background-color;

  &__tab {
    padding: 12px;
    width: 100%;
    display: flex;
    justify-content: center;
    color: $text-color;
    cursor: pointer;
    font-weight: 700;
    text-decoration: none;
    border-bottom: 2px solid transparent;

    &:hover {
      text-decoration: none;
    }

    &--active {
      color: $success-100;
      border-bottom: 2px solid $success-100;
      transition: all .3s;
    }
  }
}
