@import "../../style/typography";
@import "../../style/variables";

.ui-text {
  &--normal {
    font-size: $fontSizeNormal;
    font-family: $font-family-secondary;
  }

  &--secondary {
    font-size: $font-size-secondary;
    font-family: $font-family-secondary;
  }

  &--description {
    font-size: $font-size-description;
    font-family: $font-family-secondary;
  }

  &--xsmall {
    font-size: $font-size-system;
    font-family: $font-family-secondary;
  }

  &--small {
    font-size: $font-size-text;
    font-family: $font-family-secondary;
  }

  &--body {
    font-size: $font-size-body;
    font-family: $font-family-secondary;
  }

  &--large {
    font-size: $font-size-h4;
    font-family: $font-family-primary;
  }

  &--xlarge {
    font-size: $fontSizeXLarge;
    font-family: $font-family-primary;
  }

  &--black {
    color: $black;
  }

  &--white {
    color: $white;
  }

  &--dark {
    color: $default-600;
  }

  &--primary {
    color: $primary-100;
  }

  &--info {
    color: $info-100;
  }

  &--success {
    color: $success-100;
  }

  &--warning {
    color: $warning-100;
  }

  &--danger {
    color: $danger-100;
  }

  &--light {
    color: $default-400;
  }

  &--default {
    color: $default-800;
  }

  &--purple {
    color: $text-purple;
  }

  &--link {
    color: $link-100;
    &:hover {
      color: $link-200;
    }
  }

  &--center {
    display: block;
    text-align: center;
  }

  &--right {
    text-align: end;
  }

  &--left {
    text-align: start;
  }

  &--regular {
    font-weight: $font-weight-normal; // 400
  }

  &--medium {
    font-weight: $font-weight-medium; // 500
  }

  &--semi {
    font-weight: $font-weight-semi; // 600
  }

  &--bold {
    font-weight: $font-weight-bold; // 700
  }

  &--uppercase {
    text-transform: uppercase;
  }

  &--capitalize {
    text-transform: capitalize;
  }

  &--underline {
    text-decoration-line: underline;
  }

  &--displayBlock {
    display: block;
  }

  &--overflowHidden {
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &--maxRowsAmount {
    display: -webkit-box;
    -moz-box-orient: vertical;
    -webkit-box-orient: vertical;
  }

  &--cursorPointer {
    cursor: pointer;
    &:hover {
      color: $link-200;
    }
  }

  &--noWrap {
    white-space: nowrap;
  }
}
