@import '../../style/typography';
@import '../../style/variables';

$margin-base-vertical: 15px;

.ui-heading {
  margin-bottom: .5rem;

  &__h1 {
    font-size: $font-size-h1;
  }
  &__h2 {
    font-weight: 700;
    font-size: $font-size-h2;
  }
  &__h3 {
    font-weight: 700;
    font-size: $font-size-h3;
  }
  &__h4 {
    font-weight: 700;
    font-size: $font-size-h4;
  }
  &__h5 {
    font-weight: 700;
    font-size: $font-size-primary;
    font-family: $font-family-secondary;
  }
  &__h6 {
    font-weight: 700;
    font-size: $font-size-secondary;
  }

  &--white {
    color: $white;
  }
  &--black {
    color: $black;
  }

  &--center {
    text-align: center;
  }
  &--right {
    text-align: right;
  }
  &--left {
    text-align: left;
  }

  &--uppercase {
    text-transform: uppercase;
  }
  &--capitalize {
    text-transform: capitalize;
  }
}
