@import '../../style/typography';
@import '../../style/variables';

@mixin button-filled(
  $color,
  $primary-color,
  $primary-color-hover,
  $text-color: $white,
) {
  &.uikit-button-#{$color} {
    background-color: $primary-color;
    border-color: $primary-color;
    color: $text-color;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
      color: $text-color;

      &.disabled {
        background-color: $default-200;
        border-color: $default-400;
        color: $default-400;

        svg {
          color: $default-400 !important;
        }
      }

      svg {
        color: $text-color !important;
      }
    }

    &.disabled {
      background-color: $default-200;
      border-color: $default-400;
      color: $default-400;

      svg {
        color: $default-400 !important;
      }
    }

    svg {
      color: $text-color !important;
    }
  }
}

@mixin button-outline(
  $color,
  $primary-color,
  $primary-color-hover,
  $icon-color-hover: $white,
) {
  &.uikit-button-#{$color} {
    background-color: transparent;
    border-color: $primary-color;
    color: $primary-color;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      background-color: $primary-color-hover;
      border-color: $primary-color-hover;
      color: $white;
      box-shadow: none;

      &.square {
        background-color: transparent !important;
        border-color: $primary-color-hover;
        svg {
          background-color: transparent;
          color: $primary-color-hover !important;
        }
      }

      &.disabled {
        background-color: transparent;
        border-color: $default-400;
        color: $default-400;

        svg {
          color: $default-400 !important;
        }
      }

      svg {
        color: $icon-color-hover !important;
      }
    }
    &.disabled {
      background-color: transparent;
      border-color: $default-400;
      color: $default-400;

      svg {
        color: $default-400 !important;
      }
    }

    svg {
      color: $primary-color !important;
    }
  }
}

@mixin button-link(
  $color,
  $primary-color,
  $primary-color-hover
) {
  &.uikit-button-#{$color} {
    background-color: $white;
    border: none;
    color: $primary-color;
    text-transform: initial;
    text-align: left;

    &:hover,
    &:active,
    &:focus {
      background-color: $white !important;
      border: none;
      color: $primary-color-hover !important;

      svg {
        background-color: transparent;
        color: $primary-color-hover !important;
      }

      &.disabled {
        background-color: $white;
        border-color: $default-400;
        color: $default-400 !important;

        svg {
          color: $default-400 !important;
        }
      }
    }

    &.disabled {
      background-color: $white;
      border-color: $default-400;
      color: $default-400;

      svg {
        color: $default-400 !important;
      }
    }

    svg {
      color: $primary-color !important;
    }
  }
}

@mixin button-text(
  $color,
  $primary-color,
  $primary-color-hover,
) {
  &.uikit-button-#{$color} {
    background-color: $white;
    border: none;
    color: $primary-color;
    text-transform: uppercase;

    &:hover,
    &:active,
    &:focus {
      background-color: $default-200 !important;
      border: none;
      color: $primary-color-hover;
      box-shadow: none !important;

      svg {
        color: $primary-color-hover !important;
      }

      &.disabled {
        background-color: $white;
        border-color: $default-400;
        color: $default-400;

        svg {
          color: $default-400 !important;
        }
      }
    }

    &.disabled {
      background-color: $white;
      border-color: $default-400;
      color: $default-400;

      svg {
        color: $default-400 !important;
      }
    }

    svg {
      color: $primary-color !important;
    }
  }
}

.uikit-button {
  font-family: $font-family-navigation;
  font-weight: 700;
  white-space: nowrap;
  display: inline-block;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  transition: $btn-transition;
  border-radius: .25rem;

  &:hover,
  &:active,
  &:focus {
    text-decoration: none;
  }

  @include button-filled('success', $success-100, $success-200);
  @include button-filled('danger', $danger-100, $danger-200);
  @include button-filled('dark', $default-600, $default-700);
  @include button-filled('primary', $link-200, $link-100);
  @include button-filled('light', $default-200, $default-300, $default-800);

  &__outline {
    @include button-outline('success', $success-100, $success-200);
    @include button-outline('danger', $danger-100, $danger-200);
    @include button-outline('dark', $default-600, $default-700);
    @include button-outline('primary', $link-200, $link-100);
    @include button-outline('light', $white, $default-200, $default-700);
  }

  &__link {
    @include button-link('success', $link-100, $link-200);
    @include button-link('danger', $danger-100, $danger-200);
    @include button-link('dark', $default-600, $default-700);
    @include button-link('light', $white, $default-200);
  }

  &__text {
    @include button-text('success', $default-600, $default-700);
    @include button-text('danger', $danger-100, $danger-200);
    @include button-text('dark', $default-600, $default-700);
    @include button-text('light', $default-200, $white);
  }

  &-large {
    padding: 12px;
    font-size: $fontSizeNormal;

    svg {
      height: 25px !important;
      width: 25px !important;
    }

    &.square {
      padding: 8px;
    }

    &.circle {
      width: 50px;
      height: 50px;
      padding: 0;
      border-radius: 50%;
    }
  }

  &-normal {
    padding: 5px 12px;
    font-size: $font-size-secondary;
    svg {
      height: 20px !important;
      width: 20px !important;
    }

    &.square {
      padding: 5px;
    }

    &.circle {
      width: 30px;
      height: 30px;
      padding: 0;
      border-radius: 50%;
    }
  }

  &-xsmall {
    padding: 5px;
    font-size: $fontSizeSMALL;
    svg {
      height: 14px !important;
      width: 14px !important;
    }

    &.square {
      padding: 5px;
    }

    &.circle {
      width: 20px;
      height: 20px;
      padding: 0;
      border-radius: 50%;
    }
  }

  &--fullwidth {
    width: 100%;
  }

  &--noBorder {
    border: none !important;
  }
}
