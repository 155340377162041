@import '../../../style/variables';

.ui-container {
  width: 100%;
  border-radius: 12px;
  position: relative;
  margin-bottom: 24px;

  &--white {
    background-color: $background-color;
  }

  &--black {
    background-color: $banner-background-color;
    color: $white;
  }

  &--dark {
    background-color: $default-200;
  }

  &--withBorder {
    box-shadow: none;
    border: 1px solid $default-300;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  &--withShadow {
    box-shadow: 0 6px 10px -4px rgba(0, 0, 0, 0.15);
    border: 0 none;
  }

  &--paddingLarge {
    padding: 20px;
  }

  &--paddingXLarge {
    padding: 30px;
  }

  &--paddingNormal {
    padding: 16px;
  }

  &--paddingNone {
    padding: 0;
  }

  &__iconWrapper:hover {
    cursor: pointer;
  }
}
