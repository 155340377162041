@import "../../style/variables";

.infoMessage {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  padding: 8px 16px;
  border-radius: 8px;

  &--warning {
    background-color: $warning-100;
  }
  &--danger {
    background-color: $danger-100;
  }
  &--light {
    background-color: $default-200;

    & svg {
      path {
        fill: $default-600;
      }
    }
  }

  &--info {
    background-color: $info-100;
  }

  &__closeButton {
    background-color: $warning-100;
    position: absolute;
    right: 15px;
  }
}
