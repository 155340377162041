$font-family-primary: "Roboto", sans-serif;
$font-family-secondary: "Fira Sans", sans-serif;
$font-family-navigation: "Montserrat", sans-serif;

// spacer

$spacingBase: 4px;

// font-sizes

$fontSizeXXSMALL: $spacingBase; // 4px
$fontSizeXSMALL: $spacingBase * 2; // 8px
$fontSizeSMALL: $spacingBase * 3; // 12px
$fontSizeNormal: $spacingBase * 4; // 16px
$fontSizeLarge: $spacingBase * 5; // 20px
$fontSizeXLarge: $spacingBase * 6; // 24px
$fontSizeXXLarge: $spacingBase * 8; // 32px

$font-size-h1: 34px;
$font-size-h2: 28px;
$font-size-h3: $fontSizeXLarge;
$font-size-h4: $fontSizeLarge;
$font-size-primary: $fontSizeNormal;
$font-size-secondary: 14px;
$font-size-description: 13px;
$font-size-body: 11px;
$font-size-text: $fontSizeSMALL;
$font-size-system: $fontSizeXSMALL;

// font weights

$font-weight-light: 300;
$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-semi: 600;
$font-weight-bold: 700;

// text transforms

$text-capitalize: capitalize;
$text-uppercase: uppercase;
